








import { Component, Vue } from "vue-property-decorator";
import Informes from "@/components/Municipalidad/Informes.vue";

@Component({
  components: {
    Informes
  }
})
export default class InformesView extends Vue {}
